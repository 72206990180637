/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import type {
  CertonaRecommendation,
  AddCertonaItemObjectType,
  CtjAnalyticsType,
} from '../interface';
import type { AddToCartData, CertonaItem } from '@/types';
import CertonaPartsCarousel from './CertonaPartsCarousel';
import { useHeaderData } from '@/features/header/api/getHeader';
import { defaultStoreUS } from '@/constants/locale';
import { useGetProductSkuPrice } from '@/features/product/api/getProductSkuPrice';
import { useProductAvailability } from '@/features/product/api/getProductAvailability';
import { useDeviceType } from '@/utils/useDeviceType';
import root from 'window-or-global';

export type CertonaBlockProps = {
  id?: string;
  contentItem?: {
    tablet: boolean;
    desktop: boolean;
    '@type'?: string;
    themeCode?: string;
    name: string;
    mobile: boolean;
    containerID?: string;
  };
  className?: string;
  containerType?: string;
  isFromExternal?: boolean;
  onCertonaRecommendationClick?: () => void;
  certonaRecommendations?: CertonaRecommendation[];
  checkContainerNameIncludes?: boolean;
  onCertonaAddToCartClick?: (a: AddToCartData, blockId?: string) => void;
  updateAfterAddtoCartClick?: (item?: AddToCartData & { orderType?: string }) => void;
  onlyShowAvailableProductRecommendations?: boolean;
  displayCertonaBlock?: boolean;
  thresholdHeaderText?: string;
  subtitle?: string;
  inDrawer?: boolean;
  LoadingSkeleton?: React.ElementType<unknown> | null;
  cardsContainerCustomClass?: string;
  setAddedCertonaItemObject?: React.Dispatch<React.SetStateAction<AddCertonaItemObjectType | null>>;
  isOos?: boolean;
  doesNotFit?: boolean;
  shelfSeoUrl?: string;
  title?: JSX.Element | string;
  showInitialSkeleton?: boolean;
  isCompleteTheJob?: boolean;
  getCtjAnalytics?: (skuId: string) => CtjAnalyticsType;
};

export const CertonaBlock = ({
  id,
  contentItem,
  className,
  isFromExternal,
  onCertonaRecommendationClick,
  onCertonaAddToCartClick,
  updateAfterAddtoCartClick,
  certonaRecommendations,
  onlyShowAvailableProductRecommendations = false,
  containerType = '',
  displayCertonaBlock = true,
  thresholdHeaderText = '',
  subtitle = '',
  inDrawer = false,
  LoadingSkeleton,
  cardsContainerCustomClass,
  setAddedCertonaItemObject,
  isOos,
  doesNotFit,
  shelfSeoUrl,
  checkContainerNameIncludes = false,
  title,
  showInitialSkeleton = false,
  isCompleteTheJob,
  getCtjAnalytics,
}: CertonaBlockProps) => {
  const isOosOrDoesNotFit = isOos || doesNotFit;
  const deviceType = useDeviceType();
  const isBot = deviceType === 'bot';
  const certonaAddToCartEnabled = !isBot && onlyShowAvailableProductRecommendations;
  const locale = useLocale();

  const isAccessoriesPdp =
    !__IS_SERVER__ && window.certona && window.certona.pagetype === 'accessoriesproduct';
  const index =
    (checkContainerNameIncludes && !!contentItem?.containerID
      ? certonaRecommendations?.findIndex(
          (item) =>
            item.scheme.includes(contentItem.containerID ?? '') &&
            ((isAccessoriesPdp && (contentItem.containerID ?? '').includes('accessories')) ||
              !isAccessoriesPdp)
        )
      : certonaRecommendations?.findIndex((item) => item.scheme === contentItem?.containerID)) ??
    -1;
  const items = certonaRecommendations?.[index]?.items;
  const skuNumbers = items?.map((part) => part.id);

  const addToCartAnalyticsandSuccessMessage = (analyticsData: AddToCartData, blockId?: string) => {
    onCertonaAddToCartClick?.(analyticsData, blockId);
  };
  const {
    data: productAvailability,
    isFetching: productAvailabilityIsFetching,
    isSuccess: productAvailabilityIsSuccess,
  } = useProductAvailability({
    skuIds: skuNumbers ?? null,
    enabled: !!skuNumbers?.length && certonaAddToCartEnabled && displayCertonaBlock,
  });

  const { data: headerData } = useHeaderData();
  const storeNumber = headerData?.storeNumber ?? defaultStoreUS;
  const { data: pricingInfo } = useGetProductSkuPrice({
    skuNumbers,
    storeNumber,
    enabled: !!storeNumber && !!skuNumbers?.length,
    staleTime: 5 * 60000, // 5 minutes
  });

  const skuDetailsData =
    productAvailabilityIsSuccess && certonaAddToCartEnabled ? productAvailability : null;

  const availableProducts = items?.reduce<CertonaItem[]>((acc, item) => {
    //thresold recommendations container should only show available STH home products,Items which are only available for Same day delivery should not be included in the container
    const isAvailable = skuDetailsData?.some((skudata) => {
      const productAvailibility = thresholdHeaderText
        ? skudata.availabilityInfoVO.sthQuantity > 0
        : skudata.availabilityInfoVO.bopusQuantity > 0 ||
          skudata.availabilityInfoVO.sthQuantity > 0;
      // Customizable sku's products with no price should be still shown inside the certona container
      const pricingAvailability = !!pricingInfo?.[item.id]?.configurableSku
        ? skudata.skuId === item.id && productAvailibility
        : !!pricingInfo?.[item.id]?.totalPrice && productAvailibility && skudata.skuId === item.id;
      if (pricingAvailability) {
        return skudata;
      }
    });

    if (isAvailable) {
      acc = [...acc, item];
    }
    return acc;
  }, []);

  const showCertonaBlock = certonaAddToCartEnabled
    ? productAvailabilityIsSuccess && !!availableProducts && displayCertonaBlock
    : displayCertonaBlock;

  const recommendationsData = certonaAddToCartEnabled
    ? availableProducts
    : certonaRecommendations?.[index]?.items;

  const showSkeleton = showInitialSkeleton
    ? certonaAddToCartEnabled
      ? (!skuNumbers || productAvailabilityIsFetching) && displayCertonaBlock
      : displayCertonaBlock
    : (root.certonaNotCalled || productAvailabilityIsFetching) && !!skuNumbers?.length;

  if (locale === countryCodes.mx || locale === countryCodes.ptBr) {
    return null;
  }

  return certonaRecommendations?.[index]?.display &&
    certonaRecommendations[index]?.items.length > 0 &&
    showCertonaBlock ? (
    <DeviceWrapper
      desktop={contentItem?.desktop || false}
      tablet={contentItem?.tablet || false}
      mobile={contentItem?.mobile || false}
    >
      <div className={className} data-testid="certona-carousel-container">
        {title || null}
        <CertonaPartsCarousel
          id={id}
          title={isOosOrDoesNotFit ? '' : certonaRecommendations[index].explanation}
          subtitle={subtitle}
          // @ts-expect-error fix type
          partsList={recommendationsData}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          pageType={isOosOrDoesNotFit ? 'pencilpdp' : window?.certona?.pagetype}
          greyBackground={contentItem?.themeCode?.toLowerCase() === 'grey' || isFromExternal}
          isFromExternal={isFromExternal}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          addToCartAnalyticsandSuccessMessage={(analyticsData: AddToCartData) =>
            addToCartAnalyticsandSuccessMessage(analyticsData, id)
          }
          updateAfterAddtoCartClick={updateAfterAddtoCartClick}
          skuDetailsData={skuDetailsData}
          containerID={containerType}
          pricingInfo={pricingInfo}
          thresholdHeaderText={thresholdHeaderText}
          inDrawer={inDrawer}
          cardsContainerCustomClass={cardsContainerCustomClass}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
          isOos={isOos}
          doesNotFit={doesNotFit}
          shelfSeoUrl={shelfSeoUrl}
          isCompleteTheJob={isCompleteTheJob}
          getCtjAnalytics={getCtjAnalytics}
        />
      </div>
    </DeviceWrapper>
  ) : LoadingSkeleton && showSkeleton ? (
    <div className={className}>
      <LoadingSkeleton />
    </div>
  ) : null;
};
